.carousel-wrap {
  margin: 40px auto 90px;
  padding: 0 5%;
  position: relative;
}

.testimonial-author-tag {
  display: flex;
  align-items: center;
  margin-top: 3rem;
}

.testimonial-author-image-container {
  position: relative;
  //width: 64px;
  //height: 64px;
  margin-right: 1.5rem;

  .testimonial-author-image {
    overflow: hidden;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    position: relative;
    border: 2px solid #fff;
    top: 2px;
    left: 2px;

    img {
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      min-width: 100%;
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border-radius: 50%;
    border: 2px solid #ff7a59;
  }
}

.owl-carousel .owl-item {
  position: relative;
  padding: 1rem;
  z-index: 100;

  .rating__wrapper {
    color: #b97c1c;
    margin-bottom: 1rem;
  }

  .content__wrapper {
    position: relative;

    /* 		&::before,
		&::after {
			position: absolute;
			font-family: "FontAwesome";
			font-size: 40px;
			opacity: 0.1;
			z-index: 1;
		}

		&::before {
			content: "\f10d";
			top: -20px;
			left: -10px;
		}

		&::after {
			content: "\f10e";
			bottom: 0;
			right: -10px;
		} */

    p {
      position: relative;
      z-index: 2;
    }
  }

  .testimonial__author {
    font-weight: 500;
    color: #125272;
  }
}


#testimonial-section {
  .owl-nav {
    position: absolute;
    width: 100%;
    top: 50%;

    i {
      font-size: 42px;
      opacity: 0.7;
      transition: opacity 0.3s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }

    .owl-prev,
    .owl-next {
      position: absolute;
      border: 1px solid #81ad42;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      top: 50%;
      margin-top: -26px;
      text-align: center;
      transition: all .3s ease-in-out;

      .fa {
        font-size: 30px;
        //color: #81ad42;
      }

      &:hover {
        background: #81ad42;
        color: #fff;
      }
    }

    .owl-prev {
      left: -60px;

      .fa {
        padding-right: 4px;
      }
    }

    .owl-next {
      right: -50px;

      .fa {
        padding-left: 4px;
      }
    }


  }
}